import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    Heading: {
      variants: {
        primary: {
          fontSize: "36px",
          lineHeight: "43px",
          letterSpacing: "-0.007em",
          textAlign: "center",
          fontWeight: "medium",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: "400",
        margin: 0,
      },
    },
    Button: {
      variants: {
        primary: {
          p: "16px 35px",
          lineHeight: "19px",
          fontWeight: 600,
          letterSpacing: "0.012em",
          bg: "white",
          color: "#11131C",
          borderRadius: 0,
          border: "2px solid #11131C",
        },
      },
    },
  },
  colors: {
    gray: {
      "900": "#181B23",
    },
    white: {
      "100": "#ffffff",
    },
    red: {
      "100": "#FF1628",
    },
    green: {
      "100": "#70e2cb",
      "900": "#445D72",
    },
    teal: {
      "200": "#718F94",
    },
    blue: {
      "400": "#8AA2A9",
    },
    purple: {
      "100": "#F6E4F6",
    },
  },
  fonts: {
    heading: "Gilroy",
    body: "Gilroy",
    
  },
  styles: {
    global: {
      body: {
        // bg: "#F5F5F5",
        bg: "#fff",
        color: "black",
      },
    },
  },
});
