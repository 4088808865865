import {
  Flex,
  Heading,
  Stack,
  Text,
  GridItem,
  Image,
  Box,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { Company } from "../../@types";
import { certifiedCompany, noImageAvailable } from "../../assets/images";

interface CompanyCardProps {
  company: Company;
}

export const CompanyCard = ({ company }: CompanyCardProps) => {
  const logo = company.photos ? company.photos.split(",") : [noImageAvailable];
  const sectorWithoutNumbers = company.sector
    ?.replace(/\d+/g, "")
    .replaceAll(".", "");

  return (
    <GridItem colSpan={1}>
      <LinkBox
        as="article"
        maxW={["100%", "379px"]}
        w="100%"
        borderRadius={0}
        boxShadow="none"
        bg="none"
      >
        <Box p={"12px 15px"} h="388px">
          <Image src={logo[0]} alt="Company" h="240px" />
          <Stack mt="7px" spacing="6px">
            <Flex h="20px" align="center" gap="10px">
              <Image src={certifiedCompany} alt="Empresa certificada" />
              <Text
                fontSize="12px"
                lineHeight="20px"
                fontWeight={"bold"}
                letterSpacing="0.0047em"
                color="red.100"
              >
                EMPRESA CERTIFICADA
              </Text>
            </Flex>

            <Heading
              as="h5"
              fontSize="20px"
              lineHeight={"28px"}
              color="#11131C"
              letterSpacing="-0.0047em"
            >
              <LinkOverlay href={company.cnpj}>
                {company.fantasyName}
              </LinkOverlay>
            </Heading>

            <Text
              fontWeight={700}
              color="#11131C"
              lineHeight="28px"
              letterSpacing="-0.002em"
            >
              {sectorWithoutNumbers}
            </Text>
            <Text fontSize="14px" color="#11131C" lineHeight="28px">
              {company.cidade} / {company.UF}
            </Text>
          </Stack>
        </Box>
      </LinkBox>
    </GridItem>
  );
};
