import React, { useState, useEffect } from 'react';
import { Company } from '../../@types';
import {
    Image,
  } from "@chakra-ui/react";

  interface ImageSeloProps {
    company: Company;
  }

  const ImageSelo: React.FC<ImageSeloProps> = ({ company }) => {  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      try {

        const dateStart = new Date(Number(company.DataCertificacao));
        const dateStartString = getShortMonth(dateStart.getMonth()) + ' ' + dateStart.getFullYear();
        const dateEndString = getShortMonth(dateStart.getMonth()) + ' ' + (dateStart.getFullYear()+1);

        const url = `https://connect.gptw.info/api/result/selo-certificacao?text=${dateStartString}%20-%20${dateEndString}&type=png&survey=${company.idCompanyRegister}&qr=false`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Erro ao buscar a selo');
        }
        const base64String = await response.text(); // Assumindo que a resposta é uma string com o código base64
        setBase64Image(base64String);
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchImage();
  }, []); // O array vazio [] faz com que o efeito seja executado apenas uma vez após o componente ser montado

    function getShortMonth(index:any) {
        var months = [
          "JAN",
          "FEV",
          "MAR",
          "ABR",
          "MAI",
          "JUN",
          "JUL",
          "AGO",
          "SET",
          "OUT",
          "NOV",
          "DEZ",];
        return months[index];
    }

  return (
    <div>
      {base64Image ? (
        <Image src={base64Image}
        alt={company?.fantasyName}
        minW={["100px", "160px"]}
        height={"100%"}/>
      ) : (
        <p style={{ fontSize: "12px" }}>Carregando selo...</p>
      )}
    </div>
  );
};

export default ImageSelo;