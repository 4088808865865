import { Stack, Flex, Button, Heading } from "@chakra-ui/react";
import { backgoroundCallToAction } from "../../assets/images";

export default function CTAWithBackgroundImage() {
  return (
    <Flex
      maxW={["100%", "1170px"]}
      mx="auto"
      mt="110px"
      mb="90px"
      h={"472px"}
      backgroundImage={backgoroundCallToAction}
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
    >
      <Stack
        w={["90%", "508px"]}
        justify={"center"}
        pl={["22px", "42px"]}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={"31px"}>
          <Heading
            color={"white"}
            fontWeight={700}
            lineHeight={"60px"}
            fontSize="48px"
            letterSpacing={"-0.0112em"}
          >
            Transforme a sua empresa em um Great Place To Work®
          </Heading>

          <Button
            as="a"
            href="https://gptw.com.br/contato/onde-estamos/#contact"
            target="_blank"
            w="192px"
            h="51px"
            color="#11131C"
            letterSpacing="0.012em"
            bgColor="white"
            border="2px solid #11131C"
            borderRadius={0}
          >
            FALE CONOSCO
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
