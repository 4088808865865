import { useQuery } from "@tanstack/react-query";
import { Company } from "../@types";
import { api } from "./api";

interface MetaResponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

interface FetchAllCompaniesResponse {
  data: Company[];
  meta: MetaResponse;
}

export async function fetchAllCompanies(cnpj: string): Promise<Company[]> {


  const { data } = await api.get<FetchAllCompaniesResponse>(
    `/certified/all/filter?cnpj=${cnpj}`
  );


  return data.data;
}