import axios from "axios";

const username = window["runConfig"].CERTIFIEDS_USERNAME;
const password = window['runConfig'].CERTIFIEDS_PASSWORD;

export const api = axios.create({
  baseURL: window["runConfig"].URL_CERTIFIEDS,
  auth: {
    username: username,
    password: password,
  },
});


