import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Flex,
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  GridItem,
  FormLabel,
  Input,
  Grid,
  FormControl,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { CompanyCard } from "../../components/CompanysGrid";
import CTAWithBackgroundImage from "../../components/CTA";
import { Pagination } from "../../components/Pagination";
import { AiOutlineSearch } from "react-icons/ai";
import { size, state } from "../../data/search";
import { industry } from "../../data/industry";
import { Company } from "../../@types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../../services/api";
import queryClient from "../../services/queryCliente";
import { cnpj } from "cpf-cnpj-validator";

interface FormProps {
  companie: string;
  state: string;
  industry: string;
  size: string;
}

interface MetaResponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

interface FetchCertifiedCompaniesResponse {
  hasError: boolean;
  data: Company[];
  meta: MetaResponse;
}

export interface CertifiedCompanies {
  companies: Company[];
}

export interface CertifiedCompaniesPage {
  companies: Company[];
  meta: MetaResponse;
}

export function Home() {
  const [page, setPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, watch } = useForm<FormProps>();
  const formValues = watch(["companie", "industry", "size", "state"]);

  async function fetchCertifiedCompanies(
    currentPage: number
  ): Promise<CertifiedCompaniesPage> {
    const [companie, industry, size, state] = formValues;

    const params = new URLSearchParams();
    if (companie && companie !== "") {
      if (cnpj.isValid(companie)) {
        const removeCharacters = companie.replace(/[./-]/g, "");
        params.append("cnpj", removeCharacters);
      } else {
        params.append("fantasyName", companie);
      }
    }

    industry && industry !== "" && params.append("sector", industry);
    state && state !== "" && params.append("UF", state);

    let data;
    const response = await api.get<FetchCertifiedCompaniesResponse>(
      `/certified/all/filter?page=${currentPage}&take=15`,
      {
        params: params,
      }
    );
    if (response?.data?.data.length > 0) {
      data = response.data;
    } else {
      params.delete("fantasyName");

      const res = await api.get<FetchCertifiedCompaniesResponse>(
        `/certified/all/filter?page=${currentPage}&take=15&razaoSocial=${companie}`,
        {
          params: params,
        }
      );

      data = res.data;
    }

    let companies;

    if (size && size !== "" && data?.data.length > 0) {
      if (size === "Pequena") {
        const small = data.data.filter((d) => d.TotalEmployees < 100);
        companies = small?.filter((d, index) => index < 12);
      } else if (size === "Grande") {
        const big = data.data.filter((d) => d.TotalEmployees > 1000);
        companies = big?.filter((d, index) => index < 12);
      } else {
        const medium = data?.data.filter(
          (d) => d.TotalEmployees > 99 && d.TotalEmployees < 1000
        );
        companies = medium?.filter((d, index) => index < 12);
      }
    } else {
      companies = data.data;
    }
    const { meta } = data;
    setIsSubmitting(false);
    return {
      companies,
      meta,
    };
  }

  const { status, data, isFetching } = useQuery({
    queryKey: ["companies", page],
    queryFn: () => fetchCertifiedCompanies(page),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // staleTime: 5000,
  });

  const mutation = useMutation({
    mutationFn: () => fetchCertifiedCompanies(1),
    onSuccess: (data) => {
      queryClient.setQueryData(["companies", 1], data);
    },
  });

  const onSubmit: SubmitHandler<FormProps> = async (values) => {
    setIsSubmitting(true);
    mutation.mutate();
  };

  return (
    <>
      <Container maxW={["100%", "1170px"]}>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            color={"black"}
            textAlign={"center"}
            align={"center"}
            spacing={["19px"]}
            py="60px"
            mx="auto"
          >
            <Heading
              fontWeight={600}
              fontSize={["48px"]}
              lineHeight={"60px"}
              letterSpacing="-0.0112em"
            >
              Empresas Certificadas
              <br />
              Great Place To Work®
            </Heading>
            <Text
              fontSize="20px"
              lineHeight="28px"
              maxW={"3xl"}
              letterSpacing="-0.0047em"
            >
              Temos orgulho de reconhecer essas empresas como excelentes locais
              de trabalho validados pelos funcionários!
            </Text>
          </Stack>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(9, 1fr)"]}
            mt="10px"
            gap="26px"
            justifyContent="flex-start"
            maxW={["unset", "1270px"]}
            mx="auto"
          >
            <GridItem colSpan={[1, 3]} maxW={["100%", "422px"]}>
              <FormLabel
                fontSize={"14px"}
                lineHeight="20px"
                letterSpacing="-0.0047em"
                color="#5C6066"
                mb={"3px"}
              >
                Pesquisa por palavra-chave ou CNPJ:
              </FormLabel>
              <Input
                {...register("companie")}
                h="48px"
                border="1px solid #11131C"
                borderRadius={0}
                bg="white"
                color="#444444"
              />
            </GridItem>
            <GridItem colSpan={[1, 2]} maxW={["100%", "296px"]}>
              <FormControl className="search-container">
                <FormLabel
                  htmlFor={"industry"}
                  fontSize={"14px"}
                  lineHeight="20px"
                  letterSpacing="-0.0047em"
                  color="#5C6066"
                  mb={"2px"}
                >
                  Ramo de Atividade
                </FormLabel>

                <Select
                  {...register("industry")}
                  width="100%"
                  maxW={"340px"}
                  height="48px"
                  color="#444"
                  fontSize="12px"
                  fontWeight={600}
                  textTransform="uppercase"
                  border="1px solid #dadada"
                  borderRadius="4px"
                >
                  {industry.map((st, index) => (
                    <option key={index} value={st.value}>
                      {st.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 2]} maxW={["100%", "231px"]}>
              <FormControl className="search-container">
                <FormLabel
                  htmlFor={"state"}
                  fontSize={"14px"}
                  lineHeight="20px"
                  letterSpacing="-0.0047em"
                  color="#5C6066"
                  mb={"2px"}
                >
                  Estado
                </FormLabel>

                <Select
                  {...register("state")}
                  width="100%"
                  maxW={"340px"}
                  height="48px"
                  color="#444"
                  fontSize="12px"
                  fontWeight={600}
                  border="1px solid #dadada"
                  borderRadius="4px"
                >
                  {state.map((st, index) => (
                    <option key={index} value={st.value}>
                      {st.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 2]} maxW={["100%", "254px"]}>
              <FormControl className="search-container">
                <FormLabel
                  htmlFor={"industry"}
                  fontSize={"14px"}
                  lineHeight="20px"
                  letterSpacing="-0.0047em"
                  color="#5C6066"
                  mb={"2px"}
                >
                  Tamanho da Empresa
                </FormLabel>

                <Select
                  {...register("size")}
                  width="100%"
                  maxW={"340px"}
                  height="48px"
                  color="#444"
                  textTransform={"uppercase"}
                  fontSize="12px"
                  fontWeight={600}
                  border="1px solid #dadada"
                  borderRadius="4px"
                >
                  {size.map((st, index) => (
                    <option key={index} value={st.value}>
                      {st.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <Flex justify={"center"} mt={["28px", "48px"]}>
            <Button
              type="submit"
              isLoading={isSubmitting}
              w="219px"
              h="51px"
              variant="primary"
              gap="2px"
              _hover={{
                color: "#FFFFFF",
                bg: "#11131C",
              }}
            >
              <AiOutlineSearch />
              PESQUISA
            </Button>
          </Flex>
        </Box>
      </Container>
      {!isFetching ? (
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
          mt={["100px", "60px"]}
          maxW={["100%", "1170px"]}
          rowGap={["60px"]}
          justifyContent="space-between"
          mx="auto"
          minH={"776px"}
        >
          {data?.companies.map((company, index) =>
            index === 6 ? (
              <React.Fragment key={index}>
                <GridItem colSpan={[1, 3]}>
                  <CTAWithBackgroundImage />
                </GridItem>
                <CompanyCard
                  key={company?.idCompanyRegister}
                  company={company}
                />
              </React.Fragment>
            ) : (
              <CompanyCard key={company?.idCompanyRegister} company={company} />
            )
          )}
        </Grid>
      ) : (
        <Flex minH="776px" justify={"center"} align="center">
          <Spinner />
        </Flex>
      )}
      <Pagination
        // @ts-ignore
        totalCountOfRegisters={data?.meta?.itemCount}
        currentPage={page}
        onPageChange={setPage}
      />
    </>
  );
}
