import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  chakra,
  VisuallyHidden,
  Image,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaTwitter, FaYoutube, FaFacebook } from "react-icons/fa";
import { instagram, linkedin } from "../../assets/images";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"700"} fontSize={"18px"} mb={3}>
      {children}
    </Text>
  );
};

export const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg="none"
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box color="white" bg="#11131C" pt="40px">
      <Container as={Stack} maxW={["100%", "1170px"]} py={10}>
        <SimpleGrid columns={{ base: 2, sm: 2, md: 8 }} spacing={[4, 8]}>
          <Stack align={"flex-start"} spacing="4px" mb={["40px", "unset"]}>
            <ListHeader>Sobre Nós</ListHeader>

            <Link
              target="_blank"
              href={"https://gptw.com.br/sobre-nos/valores-e-historia"}
            >
              Valores e História
            </Link>
            <Link
              target="_blank"
              href={"https://gptw.com.br/sobre-nos/equipe-gptw"}
            >
              Nossa Equipe
            </Link>
            <Link target="_blank" href={"https://greatplacetowork.gupy.io/"}>
              Trabalhe conosco
            </Link>
            <Link
              target="_blank"
              href={"https://gptw.com.br/sobre-nos/gptw-e-ukg/"}
            >
              GPTW e UKG
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Contato</ListHeader>
            <Link
              target="_blank"
              href="https://gptw-brasil.zendesk.com/hc/pt-br"
            >
              Central de Ajuda
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/contato/onde-estamos"
            >
              Onde Estamos
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/politica-de-privacidade/"
            >
              Política de Privacidade
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/contato/onde-estamos/#contact"
            >
              Fale Conosco
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Consultoria</ListHeader>
            <Link
              target="_blank"
              href="https://gptw.com.br/consultoria/benchmark/"
            >
              Benchmark
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/consultoria/diagnostico-organizacional/"
            >
              Diagnóstico Organizacional
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/consultoria/diversidade/"
            >
              Diversidade
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/consultoria/employee-experience/"
            >
              Employee Experience
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/consultoria/transformacao-digital/"
            >
              Transformação Digital
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Ranking</ListHeader>
            <Link target="_blank" href="https://gptw.com.br/ranking/sobre">
              Sobre o Ranking
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/ranking/melhores-empresas/"
            >
              Melhores empresas para trabalhar
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/ranking/calendario-de-rankings"
            >
              Calendário de Rankings
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Certificação</ListHeader>
            <Link target="_blank" href="https://conteudo.gptw.com.br/jornada">
              Jornada de Certificação
            </Link>
            <Link
              target="_blank"
              href="https://certificadas.gptw.com.br/certificadas"
            >
              Empresas Certificadas
            </Link>
            <Link target="_blank" href="https://conteudo.gptw.com.br/planos">
              Planos e Preços
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Conteúdo</ListHeader>
            <Link target="_blank" href="https://gptw.com.br/conteudo/#artigos">
              Artigos
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/conteudo/#downloads"
            >
              Downloads
            </Link>
          </Stack>

          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Partners</ListHeader>
            <Link target="_blank" href="https://www.gptwpartners.com.br/">
              Sobre o Partners
            </Link>
          </Stack>
          <Stack align={"flex-start"} mb={["40px", "unset"]} spacing="4px">
            <ListHeader>Eventos</ListHeader>
            <Link
              target="_blank"
              href="https://gptw.com.br/eventos/#proximosEventos"
            >
              Próximos Eventos
            </Link>
            <Link
              target="_blank"
              href="https://gptw.com.br/eventos/#eventosAnteriores"
            >
              Eventos Anteriores
            </Link>
          </Stack>
        </SimpleGrid>

        <Stack direction={"row"} spacing={4} py="40px">
          <SocialButton
            label={"Linkedin"}
            href={"https://www.linkedin.com/company/great-place-to-work-brasil"}
          >
            <Image src={linkedin} alt="Linkedin" />
          </SocialButton>
          <SocialButton
            label={"Instagram"}
            href={"https://www.instagram.com/gptwbrasil/"}
          >
            <Image src={instagram} alt="Instagram" />
          </SocialButton>
          <SocialButton
            label={"YouTube"}
            href={"https://www.youtube.com/c/GreatPlaceToWorkBrasilOficial"}
          >
            <FaYoutube fontSize={"28px"} />
          </SocialButton>
          <SocialButton
            label={"Facebook"}
            href={"https://www.facebook.com/greatplacetoworkbrasil"}
          >
            <FaFacebook fontSize={"28px"} />
          </SocialButton>
        </Stack>

        <Flex>
          <Text fontSize="18px" lineHeight={"28px"}>
            © Great Place To Work® Institute. All Rights Reserved.
          </Text>
        </Flex>
      </Container>
    </Box>
  );
}
