import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
  /* latin */
  @font-face {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  };
  /* latin */
  @font-face {
    font-family: Gilroy;
    font-style: normal;
    url('fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  };
  * latin */
  @font-face {
    font-family: Gilroy;
    font-style: normal;
    url('fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  }
  `}
  />
);

export default Fonts;
