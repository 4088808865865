export const industry = [
  { value: "", label: "TODOS",  },
  {
    label: "Agricultura, Silvicultura e Piscicultura",
    value: "Agricultura, Silvicultura e Piscicultura",
  },
  { value: "Biotecnologia e Farmacêutico", label: "Biotecnologia e Farmacêutico" },
  { value:"Construção e Infraestrutura", label: "Construção e Infraestrutura" },
  { value:"Educação e Treinamento", label: "Educação e Treinamento" },
  { value: "Eletrônicos", label: "Eletrônicos" },
  { value: "Engenharia", label: "Engenharia" },
  { value:"Hotéis e Restaurantes", label: "Hotéis e Restaurantes" },
  { value: "Imobiliário", label: "Imobiliário" },
  { value: "Mídia / Online/Internet" , label: "Mídia / Online/Internet" },
  { value:  "Mídia / Rádio", label: "Mídia / Rádio" },
  { value: "Mineração", label: "Mineração" },
  { value: "Organizações Não Governamentais e Filantrópicas", label: "Organizações Não Governamentais e Filantrópicas" },
  { value: "Outro", label: "Outro" },
  { value:"Produção e Manufaturas", label: "Produção e Manufaturas" },

  { value: "Serviços de Saúde", label: "Serviços de Saúde" },
  {
    value:"Serviços Financeiros e Seguros",
    label: "Serviços Financeiros e Seguros",
  },
  { value: "Serviços Industriais", label: "Serviços Industriais" },
  { value: "Serviços para a Terceira Idade", label: "Serviços para a Terceira Idade" },
  { value: "Serviços Profissionais", label: "Serviços Profissionais" },
  {
    value: "Serviços Sociais e Órgãos Governamentais",
    label: "Serviços Sociais e Órgãos Governamentais",
  },
  { value:"Tecnologia da Informação", label: "Tecnologia da Informação" },

  { value:"Telecomunicações", label: "Telecomunicações" },
  { value:"Transportes", label: "Transportes" },
  { value: "Varejo", label: "Varejo" },
];
