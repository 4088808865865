import { Stack, Button, Text, Flex, Image } from "@chakra-ui/react";
import { PaginationItem } from "./PaginationItem";
import { arrowBack, arrowRight } from "../../assets/images";

interface PaginationProps {
  totalCountOfRegisters: number;
  registersPerPage?: number;
  currentPage?: number;
  onPageChange: (page: number) => void;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter((page) => page > 0);
}

export function Pagination({
  totalCountOfRegisters,
  registersPerPage = 15,
  currentPage = 1,
  onPageChange,
}: PaginationProps) {
  const lastPage = Math.floor(totalCountOfRegisters / registersPerPage);

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : [];
  return (
    <Flex maxW={["100%", "1170px"]} h="228px" justify="center" mx="auto">
      <Stack
        direction="row"
        spacing="10px"
        justify="space-between"
        align="center"
      >
        <Button
          width="38px"
          height="38px"
          bg="white"
          p={0}
          border="1px solid #EAE9F2"
          borderRadius={0}
          _hover={{
            bg: "gray.500",
          }}
          // onClick={() => onPageChange(number)}
        >
          <Image src={arrowBack} alt="Arrow back" h="10px" />
        </Button>
        <Stack direction="row" spacing="10px">
          {currentPage > 1 + siblingsCount && (
            <>
              <PaginationItem onPageChange={onPageChange} number={1} />
              {currentPage > 2 + siblingsCount && (
                <Flex
                  color="#11131C"
                  width="38px"
                  align="end"
                  justify="center"
                  height="38px"
                  bg="white"
                  border="1px solid #EAE9F2"
                  pb="2px"
                >
                  ...
                </Flex>
              )}
            </>
          )}

          {previousPages.length > 0 &&
            previousPages.map((page) => {
              return (
                <PaginationItem
                  onPageChange={onPageChange}
                  key={page}
                  number={page}
                />
              );
            })}

          <PaginationItem
            onPageChange={onPageChange}
            number={currentPage}
            isCurrent
          />

          {nextPages.length > 0 &&
            nextPages.map((page) => {
              return (
                <PaginationItem
                  onPageChange={onPageChange}
                  key={page}
                  number={page}
                />
              );
            })}

          {currentPage + siblingsCount < lastPage && (
            <>
              {currentPage + 1 + siblingsCount < lastPage && (
                <Text color="gray.300" width="8" textAlign="center">
                  ...
                </Text>
              )}
              <PaginationItem onPageChange={onPageChange} number={lastPage} />
            </>
          )}
        </Stack>
        <Button
          width="38px"
          height="38px"
          bg="white"
          p={0}
          border="1px solid #EAE9F2"
          borderRadius={0}
          _hover={{
            bg: "gray.500",
          }}
          // onClick={() => onPageChange(number)}
        >
          <Image src={arrowRight} alt="Arrow back" h="10px" />
        </Button>
      </Stack>
    </Flex>
  );
}
