import { Button } from "@chakra-ui/button";

interface PaginationItemProps {
  number: number;
  isCurrent?: boolean;
  onPageChange: (page: number) => void;
}

export function PaginationItem({
  isCurrent = false,
  onPageChange,
  number,
}: PaginationItemProps) {
  function handleSrollBack() {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0 });
      document.querySelector("body");
    }
    onPageChange(number);
  }

  if (isCurrent) {
    return (
      <Button
        fontWeight={"400"}
        fontSize="21px"
        color="#FFFFFF"
        width="38px"
        height="38px"
        border="1px solid #FF1628"
        bg="red.100"
        borderRadius={0}
        disabled
        _disabled={{
          bg: "#FF1628",
          cursor: "default",
        }}
      >
        {number}
      </Button>
    );
  }

  return (
    <Button
      fontWeight={"400"}
      fontSize="21px"
      color="#11131C"
      width="38px"
      height="38px"
      bg="white"
      border="1px solid #EAE9F2"
      borderRadius={0}
      _hover={{
        bg: "gray.500",
      }}
      onClick={handleSrollBack}
    >
      {number}
    </Button>
  );
}
