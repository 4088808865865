import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery } from "@chakra-ui/react";

interface BarProps {
  score: number
}

const Bar = ({score}:BarProps) => {
  const [mobile] = useMediaQuery('(max-width: 990px)')

  const options: ApexOptions = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: mobile ? 8 : 12,
            fontFamily: "Gilroy",
            fontSize: mobile ? '26px': "38px",
            color: "#FF1628",
            fontWeight: mobile ? 600 : 700
          },
        },
        hollow: {
          size: mobile ? '52%' : "50%",
        },
      },
    },
    fill: {
      colors: ["#FF1628"],
      opacity: 1,
    },
    legend: {
      show: false,
    },
  };

  const series = [score ? score : 92];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={mobile ? 180 : 250}
      />
    </div>
  );
};

export default Bar;
