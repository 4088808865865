import Select, { components, OptionProps } from "react-select";
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { CloseIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IoMdArrowDropdown } from "react-icons/io";
import { ArrowDownFill, brazilFlag, logo } from "../../assets/images";
import locations from "../../data/locations";

const Option = (props: OptionProps<{ value: string; label: string }>) => {
  return (
    <Box
      fontSize="12px"
      color="#5c6066"
      lineHeight="20px"
      letterSpacing="0px"
      cursor="pointer"
      textDecor={"none"}
    >
      <Link
        href={props.data.value}
        target="_blank"
        textDecor={"none"}
        cursor="pointer"
        _hover={{
          color: "#FF1628",
          cursor: "pointer",
          textDecor: "none",
        }}
      >
        <components.Option {...props} />
      </Link>
    </Box>
  );
};

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box pos="sticky" bgColor={"white"} zIndex={100} top={["0", "-36px"]}>
      <Box pos="relative" zIndex={100}>
        <Flex
          display={["none", "flex"]}
          bg="white"
          color={useColorModeValue("gray.600", "white")}
          h="36px"
          p="12.66px 34px 10.66px 20px"
          borderBottom="1px solid #E6E6E6"
          align={"center"}
          pos="relative"
          zIndex={100}
        >
          <Menu
            closeOnSelect={false}
            placement="auto-end"
            // gutter={20}
            // arrowPadding={14}
            preventOverflow={true}
            eventListeners={false}
            strategy="fixed"
          >
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
              _active={{
                border: "none",
              }}
            >
              <Flex gap="8px" align="center">
                <Image src={brazilFlag} alt="Brazil" minH="19px" />
                <Image src={ArrowDownFill} alt="Arrow Down Fill" />
              </Flex>
            </MenuButton>
            <MenuList
              minWidth="180px"
              width="200px"
              maxW="200px"
              fontSize="12px"
              textTransform="uppercase"
              p={0}
              boxShadow="none"
              _active={{
                border: "none",
              }}
              className="menu-location"
            >
              <Select
                defaultMenuIsOpen={true}
                options={locations}
                components={{ Option: Option }}
                defaultValue={{
                  value: "http://www.greatplacetowork.com.br",
                  label: "Brazil",
                }}
                isSearchable={false}
                classNames={{
                  option: (state) =>
                    state.isFocused
                      ? "option-location-focus"
                      : state.isSelected
                      ? "option-location-selected"
                      : "option-location-normal",

                  control: (state) =>
                    state.isFocused
                      ? "control-location-focused"
                      : "control-location-normal",
                }}
                className="select-dropdown-locations"
              />
            </MenuList>
          </Menu>

          <Flex gap="10px" align="center" h="100%" ml="auto" pos="relative">
            <Button
              w="121px"
              h="28px"
              variant="link"
              fontSize={"14px"}
              lineHeight="28px"
              fontWeight={400}
              letterSpacing="-0.0047em"
              color="black"
              borderRadius={0}
            >
              LOGIN DO CLIENTE
            </Button>
            <Button
              w="45px"
              h="28px"
              variant="link"
              fontSize={"14px"}
              lineHeight="28px"
              fontWeight={400}
              letterSpacing="-0.0047em"
              color="black"
              borderRadius={0}
            >
              BUSCA
            </Button>
          </Flex>
        </Flex>
        <Flex
          bg="white"
          color="black"
          h={["60px", "61px"]}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          boxShadow={[
            "0px 6px 6px 0px rgb(0 0 0 / 5%)",
            "0px 4px 34px rgba(0, 0, 0, 0.05)",
          ]}
          pos="relative"
          zIndex={95}
        >
          <Flex flex={{ base: 1 }} justify="start">
            <Link href="https://gptw.com.br/" target="_blank">
              <Image src={logo} alt="Great Place To Work®" />
            </Link>

            {/* <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex> */}
          </Flex>

          <Box flex={{ base: 1, md: 0 }} pr="32px" display={["none", "flex"]}>
            <Button
              as="a"
              href="https://gptw.com.br/contato/onde-estamos/#contact"
              target="_blank"
              h="51px"
              variant="primary"
              _hover={{
                color: "#FFFFFF",
                bg: "#11131C",
              }}
            >
              FALE CONOSCO
            </Button>
          </Box>

          <Flex
            flex={{ base: 1, md: 0 }}
            justify="flex-end"
            display={{ base: "flex", md: "none" }}
            pr="8px"
            pos="relative"
            zIndex={95}
          >
            <Button
              onClick={onToggle}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
              fontSize="12px"
              lineHeight={"28px"}
              letterSpacing="2px"
            >
              {isOpen ? <CloseIcon fontSize={"18px"} /> : "MENU"}
            </Button>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </Box>
  );
}

const DesktopNav = () => {
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={"41px"} align="center">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                href={navItem.href ?? "#"}
                lineHeight="28px"
                fontWeight={400}
                color={"black"}
                letterSpacing="-0.0047em"
                textTransform="uppercase"
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack bg="white" p={"20px"} spacing={0} display={{ md: "none" }}>
      {/* {NAV_ITEMS.map((navItem, index) => (
        <MobileNavItem key={index} {...navItem} />
      ))} */}
      <Button
        as="a"
        href="https://gptw.com.br/contato/onde-estamos/#contact"
        target="_blank"
        p="16px 35px"
        h="48px"
        fontSize={"15px"}
        fontWeight={600}
        letterSpacing="0.012em"
        bg="white"
        color="#11131C"
        borderRadius={0}
        border="2px solid #11131C"
        _hover={{
          color: "#FFFFFF",
          bg: "#11131C",
        }}
      >
        FALE CONOSCO
      </Button>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack
      spacing={2}
      onClick={children && onToggle}
      bg="white"
      p="0px 0px 40px"
      h={isOpen ? "auto" : "58px"}
    >
      <Flex
        bg="white"
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={"medium"} letterSpacing="0.5px" color="#11131C">
          {label}
        </Text>
        {children && (
          <Icon
            as={IoMdArrowDropdown}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={5}
            h={5}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack align={"start"}>
          {children &&
            children.map((child, index) => (
              <Link
                key={index}
                fontWeight={500}
                href={child.href}
                fontSize="12px"
                lineHeight="28px"
                letterSpacing="0.5px"
                textTransform="uppercase"
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Início",
    // children: [
    //   {
    //     label: "Item 1",
    //     subLabel: "Item 1",
    //     href: "#",
    //   },
    //   {
    //     label: "Item 2",
    //     subLabel: "Item 1",
    //     href: "#",
    //   },
    // ],
  },
  {
    label: "Empresas certificadas",
    // children: [
    //   {
    //     label: "Item 3",
    //     subLabel: "Item 1",
    //     href: "#",
    //   },
    //   {
    //     label: "Item 4",
    //     subLabel: "Item 1",
    //     href: "#",
    //   },
    // ],
  },
];
