import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery } from "@chakra-ui/react";

const ChartExperience = () => {
  const [mobile] = useMediaQuery("(max-width: 990px)");

  const series = [
    {
      name: "Actual",
      data: [
        {
          x: "SuperCharge",
          y: 82,
          goals: [
            {
              name: "SuperCharge",
              value: 14,
              strokeWidth: 0,
              strokeDashArray: 0,
              strokeColor: "#FF1628",
            },
          ],
        },
        {
          x: "Média do Mercado",
          y: 44,
          goals: [
            {
              name: "Média do Mercado",
              value: 54,
              strokeWidth: 0,
              strokeHeight: 0
            },
          ],
        },
      ],
    },
  ];

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "88%",
        colors: {
          backgroundBarColors: ["#EDF1F7"],
          // backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return "val";
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
    },
    // colors: ["#00E396"],
    yaxis: {
      show: false,
    },

    xaxis: {
      // categories: [2008],
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    fill: {
      colors: ["#FF1628", "#5C6066"],
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={mobile ? 280 : 280}
      />
    </>
  );
};

export default ChartExperience;
