import { useQuery } from "@tanstack/react-query";
import { Company } from "../@types";
import { api } from "./api";
// import queryClient from "./queryCliente";

interface FetchCertifiedCompaniesResponse {
  hasError: boolean;
  data: Company[];
}
export interface CertifiedCompanies {
  totalCount: number;
  companies: Company[];
}
export async function fetchCertifiedCompanies(
  page: number
): Promise<CertifiedCompanies>  {

  const { data } = await api.get<FetchCertifiedCompaniesResponse>(
    `/certified/all/filter?page=${page}&take=16`
  );

  const totalCount = data?.data.length;

  const companies = data.data.map((prod) => {
    return {
      ...prod,
    };
  });

  return {
    companies,
    totalCount,
  };
}

export default function useCertified(page: number) {
  return useQuery<CertifiedCompanies>({
    queryKey: ["certified-companies", page],
    queryFn:() => fetchCertifiedCompanies(page),
    refetchOnWindowFocus: false,
    // staleTime: 5000,
  });
}
