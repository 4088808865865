/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { ActionFunctionArgs, LoaderFunctionArgs } from "react-router-dom";
import {
  Await,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Link,
  Outlet,
  Route,
  RouterProvider,
  useAsyncError,
  useAsyncValue,
  useFetcher,
  useFetchers,
  useLoaderData,
  useNavigation,
  useParams,
  useRevalidator,
  useRouteError,
} from "react-router-dom";
import Fonts from "./styles/fonts";
import { theme } from "./styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { ReactElement } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Home } from "./pages/Home";
import { Detalhe } from "./pages/Detalhe";
import NotFound from "./pages/NotFound";
import queryClient from "./services/queryCliente";
import { CertifiedCompanies, fetchCertifiedCompanies } from "./services/use-certified";
import { Company } from "./@types";
import { fetchAllCompanies } from "./services/use-certified-details";

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route
        index
        action={companiesAction}
        loader={companiesLoader}
        element={<Home />}
      />
      <Route path=":id" loader={todoLoader} element={<Detalhe />} />

      <Route
        path="deferred"
        loader={deferredLoader}
        element={<DeferredPage />}
      />
    </Route>
  )
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen />
      <ChakraProvider theme={theme}>
        <Fonts />
        <RouterProvider router={router} fallbackElement={<Fallback />} />
      </ChakraProvider>
    </QueryClientProvider>
  );
}
export function sleep(n: number = 500) {
  return new Promise((r) => setTimeout(r, n));
}

export function Fallback() {
  return <p></p>;
}

// Layout
export function Layout() {
  let navigation = useNavigation();
  let revalidator = useRevalidator();
  let fetchers = useFetchers();
  let fetcherInProgress = fetchers.some((f) =>
    ["loading", "submitting"].includes(f.state)
  );
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export async function companiesLoader(): Promise<CertifiedCompanies> {
  await sleep();
  return fetchCertifiedCompanies(1);
}

export async function companiesAction({ request }: ActionFunctionArgs) {
  await sleep();

  return new Response(null, {
    status: 302,
    headers: { Location: "/" },
  });
}

// export async function companiesLoader(): Promise<Company[]> {
//   await sleep();
//   return companys;
// }

// export function TodosList() {
//   let company = companys;
//   let navigation = useNavigation();
//   let formRef = React.useRef<HTMLFormElement>(null);

//   let [isAdding, setIsAdding] = React.useState(false);
//   React.useEffect(() => {
//     if (navigation.formData?.get("action") === "add") {
//       setIsAdding(true);
//     } else if (navigation.state === "idle") {
//       setIsAdding(false);
//       formRef.current?.reset();
//     }
//   }, [navigation]);

//   return (
//     <>
//       <Outlet />
//     </>
//   );
// }

export function TodosBoundary() {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
}

interface TodoItemProps {
  id: string;
  todo: Company;
}

export function TodoItem({ id, todo }: TodoItemProps) {
  let fetcher = useFetcher();

  let isDeleting = fetcher.formData != null;
  return (
    <>
      <Link to={`/todos/${id}`}>{todo.fantasyName}</Link>
      <fetcher.Form method="post" style={{ display: "inline" }}>
        <input type="hidden" name="action" value="delete" />
        <button type="submit" name="todoId" value={id} disabled={isDeleting}>
          {isDeleting ? "Deleting..." : "Delete"}
        </button>
      </fetcher.Form>
    </>
  );
}

// Todo
export async function todoLoader({
  params,
}: LoaderFunctionArgs): Promise<Company | undefined> {

  await sleep(); 
  // @ts-ignore
  const companies = await fetchAllCompanies(params.id)

  if (!params.id) {
    throw new Error("Expected params.id");
  }
  
  const company = companies[0]
  // console.log("todo", todo);

  if (!company) {
    // return <NotFound />;
    // router.push
    // throw new Error(`Uh oh, I couldn't find a todo with id "${params.id}"`);
  }
  return company;
}

export function Todo() {
  let params = useParams();
  let todo = useLoaderData() as string;
  return (
    <>
      <h2>Nested Todo Route:</h2>
      <p>id: {params.id}</p>
      <p>todo: {todo}</p>
    </>
  );
}

// Deferred Data
interface DeferredRouteLoaderData {
  critical1: string;
  critical2: string;
  lazyResolved: Promise<string>;
  lazy1: Promise<string>;
  lazy2: Promise<string>;
  lazy3: Promise<string>;
  lazyError: Promise<string>;
}

const rand = () => Math.round(Math.random() * 100);
const resolve = (d: string, ms: number) =>
  new Promise((r) => setTimeout(() => r(`${d} - ${rand()}`), ms));
const reject = (d: Error | string, ms: number) =>
  new Promise((_, r) =>
    setTimeout(() => {
      if (d instanceof Error) {
        d.message += ` - ${rand()}`;
      } else {
        d += ` - ${rand()}`;
      }
      r(d);
    }, ms)
  );

export async function deferredLoader() {
  return defer({
    critical1: await resolve("Critical 1", 250),
    critical2: await resolve("Critical 2", 500),
    lazyResolved: Promise.resolve("Lazy Data immediately resolved - " + rand()),
    lazy1: resolve("Lazy 1", 1000),
    lazy2: resolve("Lazy 2", 1500),
    lazy3: resolve("Lazy 3", 2000),
    lazyError: reject(new Error("Kaboom!"), 2500),
  });
}

export function DeferredPage() {
  let data = useLoaderData() as DeferredRouteLoaderData;
  return (
    <div>
      {/* Critical data renders immediately */}
      <p>{data.critical1}</p>
      <p>{data.critical2}</p>

      {/* Pre-resolved deferred data never triggers the fallback */}
      <React.Suspense fallback={<p>should not see me!</p>}>
        <Await resolve={data.lazyResolved}>
          <RenderAwaitedData />
        </Await>
      </React.Suspense>

      {/* Deferred data can be rendered using a component + the useAsyncValue() hook */}
      <React.Suspense fallback={<p>loading 1...</p>}>
        <Await resolve={data.lazy1}>
          <RenderAwaitedData />
        </Await>
      </React.Suspense>

      <React.Suspense fallback={<p>loading 2...</p>}>
        <Await resolve={data.lazy2}>
          <RenderAwaitedData />
        </Await>
      </React.Suspense>

      {/* Or you can bypass the hook and use a render function */}
      <React.Suspense fallback={<p>loading 3...</p>}>
        <Await resolve={data.lazy3}>{(data: string) => <p>{data}</p>}</Await>
      </React.Suspense>

      {/* Deferred rejections render using the useAsyncError hook */}
      <React.Suspense fallback={<p>loading (error)...</p>}>
        <Await resolve={data.lazyError} errorElement={<RenderAwaitedError />}>
          <RenderAwaitedData />
        </Await>
      </React.Suspense>
    </div>
  );
}

function RenderAwaitedData() {
  let data = useAsyncValue() as string;
  return <p>{data}</p>;
}

function RenderAwaitedError() {
  let error = useAsyncError() as Error;
  return (
    <p style={{ color: "red" }}>
      Error (errorElement)!
      <br />
      {error.message} {error.stack}
    </p>
  );
}

export default App;
