const options = [
  { value: "http://www.greatplacetowork.com.ar/", label: "Argentina" },
  { value: "http://www.greatplacetowork.com.au", label: "Australia" },
  { value: "http://www.greatplacetowork.at", label: "Austria" },
  { value: "http://www.greatplacetowork.be", label: "Belgium" },
  { value: "http://www.greatplacetowork.com.bo", label: "Bolivia" },
  { value: "http://www.greatplacetowork.com.br", label: "Brazil" },
  { value: "http://www.greatplacetowork.ca", label: "Canada (English)" },
  { value: "http://www.greatplacetowork.ca/fr", label: "Canada (French)" },
  {
    value: "https://greatplacetowork-cayc.com/",
    label: "Central America & Caribbean (Spanish)",
  },
  { value: "http://www.greatplacetowork.cl", label: "Chile" },
  { value: "http://www.greatplacetowork.com.co", label: "Colombia" },
  { value: "http://greatplacetowork.dk", label: "Denmark" },
  { value: "http://www.greatplacetowork.com.ec", label: "Ecuador" },
  { value: "http://www.greatplacetowork.fi", label: "Finland" },
  { value: "http://www.greatplacetowork.fr", label: "France" },
  { value: "http://www.greatplacetowork.de", label: "Germany" },
  { value: "https://www.greatplacetowork.com.gh", label: "Ghana" },
  {
    value: "http://www.greatplacetowork.cn/",
    label: "Greater China (Chinese) ",
  },
  { value: "http://en.greatplacetowork.cn", label: "Greater China (English)" },
  { value: "http://www.greatplacetowork.gr", label: "Greece" },
  {
    value: "http://www.greatplacetowork.com.hk",
    label: "Hong Kong SAR (English) ",
  },
  { value: "http://www.greatplacetowork.in", label: "India" },
  { value: "http://www.greatplacetowork.ie", label: "Ireland" },
  { value: "http://www.greatplacetowork.it", label: "Italy" },
  { value: "http://hatarakigai.info", label: "Japan" },
  { value: "https://www.greatplacetowork.co.ke/", label: "Kenya" },
  { value: "http://gwpkorea.com", label: "Korea" },
  { value: "http://www.greatplacetowork.lu", label: "Luxembourg" },
  { value: "http://greatplacetowork.com.mx", label: "Mexico" },
  { value: "https://www.greatplacetowork.com.ng", label: "Nigeria" },
  { value: "http://www.greatplacetowork.no", label: "Norway" },
  { value: "http://www.greatplacetowork.com.py", label: "Paraguay" },
  { value: "http://www.greatplacetowork.com.pe", label: "Peru" },
  { value: "http://www.greatplacetowork.pl", label: "Poland" },
  { value: "http://www.greatplacetowork.pt", label: "Portugal" },
  { value: "http://www.greatplacetowork.qa", label: "Qatar" },
  { value: "http://sa.greatplacetowork.com", label: "Saudi Arabia (Arabic)" },
  {
    value: "http://saen.greatplacetowork.com",
    label: "Saudi Arabia (English) ",
  },
  { value: "http://www.greatplacetowork.com.sg", label: "Singapore" },
  { value: "http://www.greatplacetowork.es", label: "Spain" },
  { value: "http://greatplacetowork.lk", label: "Sri Lanka" },
  { value: "http://www.greatplacetowork.se", label: "Sweden" },
  { value: "https://en.greatplacetowork.ch/", label: "Switzerland (English)" },
  { value: "https://fr.greatplacetowork.ch/", label: "Switzerland (French)" },
  { value: "https://www.greatplacetowork.ch/", label: "Switzerland (German)" },
  { value: "http://www.greatplacetowork.nl", label: "The Netherlands" },
  { value: "http://www.greatplacetowork.com.tr", label: "Turkey" },
  {
    value: "http://www.greatplacetowork.ae",
    label: "United Arab Emirates (English) ",
  },
  { value: "http://www.greatplacetowork.co.uk", label: "United Kingdom" },
  { value: "http://www.greatplacetowork.com", label: "United States" },
  { value: "http://www.greatplacetowork.com.uy", label: "Uruguay" },
  { value: "http://www.greatplacetowork.com.ve", label: "Venezuela" },
];

export default options;
